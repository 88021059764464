!function () {
  // iframe内で動作させる場合は以下の1行を削除もしくはコメントアウトしてください
  try { if (window.top !== window.self) return; } catch(e) { return; }
  if ('TechtouchObject' in window && document.querySelector('script#techtouch-snippet')) return;
  if ('TechtouchAddon' in window) return;
  window.TechtouchObject = {
    organizationUuid: "orga-6629f287-8ba4-475f-5844-30a1240219ff"
  };
  var e = document.createElement("script"); e.async = 1, e.src = "https://apps.techtouch.jp/script/orga-6629f287-8ba4-475f-5844-30a1240219ff/main.js?projectUuid=proj-6629f35e-100d-4a4b-2c26-152b18147553"; e.id = "techtouch-snippet";
  var t = document.getElementsByTagName("script")[0]; t.parentNode.insertBefore(e, t);
}()
